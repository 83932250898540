<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.cancel_reserved_goods") }}
      <v-spacer></v-spacer>

      <v-btn
        color="success"
        small
        outlined
        class="mr-1"
        @click="create"
        :disabled="!quantity || quantity > item.quantity"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" small outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <tbody>
            <tr>
              <td rowspan="3" class="text-center">
                <ImageViewer v-if="item.url_images" :url="item.url_images" />
              </td>
              <td style="width: 75px">{{ $t("labels.sku") }}</td>
              <td class="font-weight-medium">{{ item.sku }}</td>
              <td style="width: 75px"></td>
              <td style="width: 75px">{{ $t("labels.name") }}</td>
              <td class="font-weight-medium">{{ item.name }}</td>
              <td style="width: 75px"></td>
              <td class="text-center" style="width: 105px">
                {{ $t("labels.reserved_goods_status_1") }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("labels.category") }}</td>
              <td class="font-weight-medium">{{ item.category_name }}</td>
              <td></td>
              <td>{{ $t("labels.goods_description") }}</td>
              <td class="font-weight-medium">{{ item.description }}</td>
              <td></td>
              <td
                rowspan="2"
                class="text-center fs-22px error--text font-weight-medium"
              >
                {{ item.remaining_quantity }}
              </td>
            </tr>
            <tr>
              <td class="border-bottom-thin">{{ $t("labels.barcode") }}</td>
              <td class="border-bottom-thin font-weight-medium">
                {{ item.customer_goods_barcode }}
              </td>
              <td class="border-bottom-thin"></td>
              <td class="border-bottom-thin">{{ $t("labels.size") }}</td>
              <td class="border-bottom-thin"></td>
              <td class="border-bottom-thin font-weight-medium">
                {{ item.size }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text class="mt-5">
      <div class="mb-3 fs-14px font-weight-medium info white--text pa-2">
        {{ $t("labels.reserved_goods_cancel_prioritize") }}
      </div>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center" style="width: 20%">
                {{ $t("labels.reserved_goods_cancel_type") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 20%">
                {{ $t("labels.reserved_goods_cancel_quantity") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 20%">
                {{ $t("labels.order_export_prioritize") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 20%">
                {{ $t("labels.prioritize") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 20%">
                {{ $t("labels.select_shop") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex align-center justify-center w-100">
                  <v-radio-group
                    dense
                    v-model="type"
                    @change="onTypeChange"
                    hide-details
                    class="mt-0"
                  >
                    <v-radio
                      v-for="rct in reservedCancelTypes"
                      :key="`t_${rct.value}`"
                      :label="rct.text"
                      :value="rct.value"
                      class="c-input-small"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </td>
              <td>
                <v-text-field
                  class="c-input-small"
                  v-model.number="quantity"
                  type="number"
                  dense
                  outlined
                  hide-details
                  :disabled="type != 2"
                ></v-text-field>
              </td>
              <td>
                <div class="d-flex align-center justify-center w-100">
                  <v-radio-group
                    dense
                    v-model="prioritize"
                    @change="onPrioritizeChange"
                    hide-details
                    class="mt-0"
                  >
                    <v-radio
                      v-for="yn in yesNoOptions"
                      :key="`p_${yn.value}`"
                      :label="yn.text"
                      :value="yn.value"
                      class="c-input-small"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </td>
              <td>
                <div
                  class="d-flex align-center justify-center w-100"
                  v-if="prioritize"
                >
                  <div>
                    <v-checkbox
                      v-for="ot in orderTypeOptions"
                      :key="`o_${ot.value}`"
                      class="c-input-small"
                      dense
                      v-model="orderTypes"
                      :label="ot.text"
                      :value="ot.value"
                      hide-details
                      multiple
                      @change="onOrderTypeChange"
                    ></v-checkbox>
                  </div>
                </div>
              </td>
              <td>
                <div
                  class="d-flex align-center justify-center w-100"
                  v-if="orderTypes && orderTypes.length > 0"
                >
                  <div>
                    <v-checkbox
                      v-for="s in shopOptions"
                      :key="`s_${s.value}`"
                      class="c-input-small"
                      dense
                      v-model="shopIds"
                      :label="s.text"
                      :value="s.value"
                      hide-details
                      multiple
                    ></v-checkbox>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-if="orderTypes.includes(-1)">
      <div class="mb-3 fs-14px font-weight-medium primary--text">
        {{ $t("labels.prioritize_by_order_tracking") }}
      </div>
      <v-textarea
        class="c-input-small input-disabled-bold"
        v-model="trackings"
        :label="$t('labels.prioritize_by_order_tracking_placeholder')"
        :placeholder="$t('labels.prioritize_by_order_tracking_placeholder')"
        outlined
        dense
        hide-details
        single-line
        @paste.prevent.stop="onTrackingPaste"
      ></v-textarea>
    </v-card-text>

    <v-card-text class="error--text">
      {{ $t("labels.tracking_prioritize_first") }} <br />
      {{ $t("labels.reserved_goods_prioritize_other") }}
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "ReservedCancel",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    type: 1,
    prioritize: 0,
    quantity: null,
    orderTypes: [],
    shopIds: [],
    trackings: null,
    isLoading: false,
    hasChange: false,
    shops: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    orderTypeOptions: [],
    reservedCancelTypes: [],
  }),
  computed: {
    shopOptions() {
      if (!this.orderTypes || this.orderTypes.length === 0) {
        return [];
      }
      return [...this.shops]
        .filter((shop) => this.orderTypes.includes(shop.id_e_marketplace))
        .map((shop) => ({
          text: shop.display_name,
          value: shop.id,
        }));
    },
  },
  watch: {},
  created() {
    this.orderTypeOptions = [
      {
        text: "Tracking",
        value: -1,
      },
      {
        text: "Shopee",
        value: 1,
      },
      {
        text: "Lazada",
        value: 2,
      },
      {
        text: "Tiktok Shop",
        value: 5,
      },
      {
        text: this.$t("labels.other_e_market"),
        value: -2,
      },
    ];

    this.reservedCancelTypes = [
      {
        text: this.$t("labels.reserved_goods_all"),
        value: 1,
      },
      {
        text: this.$t("labels.reserved_goods_by_quantity"),
        value: 2,
      },
      {
        text: this.$t("labels.reserved_goods_by_order_missing"),
        value: 3,
      },
    ];
  },
  mounted() {
    this.getShop();
    this.onTypeChange();
  },
  methods: {
    async getShop() {
      try {
        const { data } = await httpClient.post("/get-all-e-market-shop", {
          active: 1,
        });
        this.shops = [...data];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    onTrackingPaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const codeSplit = value.split("\n").filter((v) => v);
        this.trackings = codeSplit.join(";");
      } else if (value.includes(" ")) {
        const codeSplit = value.split(" ").filter((v) => v);
        this.trackings = codeSplit.join(";");
      } else {
        this.trackings += value;
      }
    },

    onTypeChange() {
      if (this.type === 3) {
        httpClient
          .post("/get-missing-quantity-by-reserved", { id: this.item.id })
          .then(({ data }) => {
            this.quantity = data;
          });
      } else if (this.type === 2) {
        this.quantity = null;
      } else {
        this.quantity = this.item.remaining_quantity;
      }
    },

    onOrderTypeChange() {
      const allowIds = [...this.shopOptions].map((so) => so.value);
      const shopIds = [...this.shopIds].filter((sid) => allowIds.includes(sid));
      this.shopIds = [...shopIds];
    },

    onPrioritizeChange() {
      this.trackings = null;
      this.orderTypes = [];
      this.shopIds = [];
    },

    closeDialog() {
      if (this.hasChange) {
        this.$emit("refreshData", true);
      }
      this.$emit("cancel", true);
    },

    async create() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const tracking_ids = (
        (this.trackings && this.trackings.split(";")) ||
        []
      ).map((tracking_id) => tracking_id.trim());
      try {
        await httpClient.post("/cancel-goods-reserved-v2", {
          id: this.item.id,
          type: this.type,
          quantity: this.quantity,
          prioritize: this.prioritize,
          order_types: this.orderTypes,
          tracking_ids,
          shop_ids: this.shopIds,
        });
        this.isLoading = false;
        this.$vToastify.success(
          this.$t("messages.cancel_reserved_goods_success")
        );
        this.hasChange = true;
        this.closeDialog();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
